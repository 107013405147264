<template>
  <div class="security-page">
    <m-page-title
      title="Update Personal Profile"
      describe="*indicates required field"
    />
    <h3 class="item-title">Vehicle</h3>
    <el-form
      :model="formData"
      ref="formData"
      label-position="top"
      size="medium"
      @keyup.enter.native="saveClick"
      @submit.native.prevent
    >
      <el-form-item
        label="Vehicle make/ model (e.g. Toyota Yaris)"
        prop="bodyType"
      >
        <el-input v-model="formData.bodyType" class="form-input"></el-input>
      </el-form-item>
      <el-form-item
        label="Vehicle registration number"
        prop="registrationNumber"
      >
        <el-input
          v-model="formData.registrationNumber"
          class="form-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="Vehicle color" prop="color">
        <el-input v-model="formData.color" class="form-input"></el-input>
      </el-form-item>

      <h3 class="item-title">Agent information</h3>
      <el-form-item label="Agent Email" prop="agentEmail">
        <el-input v-model="formData.agentEmail" class="form-input"></el-input>
      </el-form-item>
      <el-form-item label="Agent Phone" prop="agentPhone">
        <el-input v-model="formData.agentPhone" class="form-input"></el-input>
      </el-form-item>
    </el-form>
    <div class="flex-row btn-box">
      <m-back />
      <el-button class="save-btn" type="primary" @click="saveClick">
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "basicInfo",
  components: {},
  data() {
    return {
      formData: {
        bodyType: null,
        registrationNumber: null,
        color: null,
        agentEmail: null,
        agentPhone: null,
      },
    };
  },
  computed: {},
  created() {
    this.getVehicle();
  },
  mounted() {},
  destroyed() {},
  methods: {
    async saveClick() {
      this.$axios.post(this.$api.vehicle, this.formData).then((res) => {
        if (res.code === "1000") {
          this.$notify.success("Saved successfully");
        }
      });
    },
    getVehicle() {
      this.$axios.get(this.$api.vehicle).then((res) => {
        if (res.code === "1000") {
          this.formData = { ...res.data.memberVehicle };
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
